<template>
  <div @click="clickToOrder" class="order-card" :class="{ 'order-card--selected': isSelectedOrder }">
    <div class="order-card__header">
      <div class="order-card__block-1">
        <div class="order-card__status order-card__status--type">{{ isOffline ? "Офлайн" : "Онлайн" }}</div>
        <div v-if="!isOffline" class="order-card__delivery-type" :class="{ 'order-card__delivery-type--express': data.express }">
          {{ data.type_id === $store.state._types.RECEIVE_PICKUP
              ? "Самовывоз"
              : (data.type_id === $store.state._types.RECEIVE_DELIVERY && !data.express
                ? "Доставка" : "Экспресс ⚡") }}
        </div>
        <template v-if="isOffline">
          <div class="order-card__time">
            <span>{{ data.created_at | formatDateTime }}</span>
          </div>
        </template>
        <template v-else>
          <div class="order-card__time">
            <span>{{ orderDateTime }}</span>
          </div>
        </template>
        <div v-if="!isOffline && !isRadio" class="order-card__status" :class="statusClass">
          <span>{{ statusName }}</span>
        </div>
      </div>
      <div class="order-card__block-2">
        <div class="order-card__count">
          <span>
            {{ data.items.length }}
            {{ data.items.length | plural_word("товар", "товара", "товаров") }}
          </span>
        </div>
        <!--        <div class="order-card__weight">-->
        <!--          <span>1,2 кг.</span>-->
        <!--        </div>-->
        <div class="order-card__price">
          <span>{{ data.amount }} ₽</span>
        </div>
        <div class="order-card__controller">
          <button
            v-if="!isOffline && !isRadio"
            @click.stop="$emit('order-repeat', data.id)"
            class="btn btn--sm btn--green-light order-card__btn-repeat"
          >
            Повторить
          </button>
          <!--          <button v-if="!isFinished" :disabled="isCanceled" class="btn btn&#45;&#45;sm btn&#45;&#45;gray">-->
          <!--            {{ isCanceled ? "Отменен" : "Отменить" }}-->
          <!--          </button>-->
          <button v-if="!isRadio" class="btn btn--sm btn--gray">Подробнее</button>
          <button
            v-if="isRadio"
            @click.stop="$emit('open-order-modal', data.id)"
            class="btn btn--sm btn--gray"
          >
            <RemixIconComponent category="system" name="more-line" />
          </button>
        </div>
        <!--        <button class="btn btn&#45;&#45;gray order-card__more" @click="openOrderModal">-->
        <!--          <RemixIconComponent category="system" name="more-line" />-->
        <!--        </button>-->
      </div>
    </div>
    <div class="order-card__content">
      <ul class="order-card__list">
        <li v-for="el in data.items" :key="el.id">
          <ImgComponent :img="el.product.img" width="200" height="200" contain />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import OrderModal from "../modals/OrderModal.vue";
import dayjs from "dayjs";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "OrderComponent",
  emits: ["open-order-modal", "order-repeat", "check-order"],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
    selectedOrderId: Number,
  },
  computed: {
    statusClass() {
      switch (this.data.status_id) {
        case this.statuses.ORDER_CREATED: {
          return "order-card__status--orange";
        }
        case this.statuses.ORDER_PREPARING:
        case this.statuses.ORDER_ASSEMBLED: {
          return "order-card__status--yellow";
        }
        case this.statuses.ORDER_IN_DELIVERY: {
          return "order-card__status--blue";
        }
        case this.statuses.ORDER_CANCELED: {
          return "order-card__status--red";
        }
      }
      return "order-card__status--green";
    },
    statusName() {
      switch (this.data.status_id) {
        case this.statuses.ORDER_CREATED: {
          return "Принят";
        }
        case this.statuses.ORDER_PREPARING: {
          return "Собираем";
        }
        case this.statuses.ORDER_ASSEMBLED: {
          return "Упаковываем";
        }
        case this.statuses.ORDER_IN_DELIVERY: {
          return "Везём";
        }
        case this.statuses.ORDER_FINISHED: {
          return "Доставили";
        }
        case this.statuses.ORDER_CANCELED: {
          return "Отменен";
        }
      }
      return null;
    },
    isFinished() {
      return this.data.status_id === this.statuses.ORDER_FINISHED;
    },
    isCanceled() {
      return this.data.status_id === this.statuses.ORDER_CANCELED;
    },
    orderDateTime() {
      dayjs.locale("ru");
      let day = dayjs(this.data.slot_start_date).format("D MMM");
      let slotStartTime = dayjs(this.data.slot_start_date).format("HH:mm");
      let slotEndTime = dayjs(this.data.slot_end_date).format("HH:mm");
      return `${day} → ${slotStartTime} - ${slotEndTime}`;
    },
    statuses() {
      return this.$store.state._statuses;
    },
    isOffline() {
      return this.data.payment_type_id === this.$store.state._types.PAYMENT_OFFLINE;
    },
    isSelectedOrder() {
      return this.data.id === this.selectedOrderId;
    },
  },
  methods: {
    clickToOrder() {
      // this.$store.state._modals.push({
      //   component: OrderModal,
      //   options: {
      //     id: this.data.id,
      //   },
      // });
      if (this.isRadio) {
        this.$emit("check-order", this.data.id);
      } else {
        this.$emit("open-order-modal", this.data.id);
      }
    },
  },
  components: { IconComponent, ImgComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.order-card {
  display flex
  flex-direction column
  gap 16px
  border 1px solid var(--border-color-2)
  padding 16px
  border-radius 16px
  cursor: pointer;
  transition var(--transition)

  &--selected {
    border-color var(--main)
  }

  &:hover {
    border-color var(--main)
  }

  &__header {
    display flex
    justify-content space-between
    align-items center
    border-bottom 1px solid var(--border-color-2)
    padding-bottom 8px
    gap 16px

    +below(940px) {
      flex-direction column
      align-items start
    }
  }

  &__block-1 {
    display flex
    gap 8px
    align-items center

    +below(1024px) {
      flex-wrap wrap
    }
  }

  &__status {
    display flex
    align-items center
    justify-content center
    gap: 4px
    border-radius: 999px;
    font-weight: 400;
    font-size: 0.750em;
    line-height 14px
    height: 30px;
    padding 8px

    &::before {
      content ""
      display block
      width: 6px
      height: 6px
      border-radius 50%
    }

    &--type {
      border: 1px solid var(--theme-border-color, #E3E3E3);
      min-width 80px

      &::before {
        display none
      }
    }

    &--orange {
      color var(--brown)
      background var(--orange-o2)

      &::before {
        background var(--brown)
      }
    }

    &--yellow {
      color var(--orange)
      background var(--yelow-o3)

      &::before {
        background var(--orange)
      }
    }

    &--blue {
      color var(--blue)
      background var(--aqua)

      &::before {
        background var(--blue)
      }
    }

    &--red {
      color var(--white)
      background var(--red)

      &::before {
        background var(--white)
      }
    }

    &--green {
      color var(--green)
      background var(--green-50)

      &::before {
        background var(--green)
      }
    }
  }

  &__delivery-type {
    display flex
    align-items center
    justify-content center
    border-radius: 999px;
    line-height 14px
    font-weight: 400;
    font-size: 0.750em;
    background var(--gray-100)
    width: 80px;
    height: 30px;

    &--express {
      background white
      border 1px solid #5AB032
      width 95px
    }
  }

  &__time {
    font-weight: 400
    font-size: 0.750em;
    line-height 14px
    color var(--gray-900)
  }

  &__filter {
    display flex
    align-items center
    justify-content center
    font-weight: 500;
    font-size: 0.750em;
    background var(--green-50)
    color var(--green)
    width: 80px;
    height: 30px;
    border-radius 999px
  }

  &__block-2 {
    display flex
    gap 16px
    align-items center

    +below(1024px) {
      flex-wrap wrap
    }
  }

  &__count {
    font-weight 400
    color var(--body_muted)
    font-size 0.875em
  }

  &__weight {
    font-weight 400
    color var(--body_muted)
    font-size 0.875em
  }

  &__price {
    font-size 0.875em
    color var(--gray-900)
  }

  &__controller {
    display flex
    gap 8px

    button {
      border-radius 12px
    }
  }

  &__content {
    font-weight 500
    font-size 0.875em
  }

  &__more {
    display flex
    align-items center
    justify-content center
    border-radius 12px
    width: 36px;
    height: 36px;
    transition var(--transition)
    background var(--gray-100)

    .icon {
      width 16px
      height 16px
    }
  }

  &__list {
    display flex
    flex-wrap wrap
    gap 5px

    li {
      width 80px
      height 80px
      display flex
    }
  }

  &__btn-repeat {
    color: var(--green-green-800, #244614);
    font-size: 0.725rem;
    font-weight: 400;
    line-height: 120%;

    &:hover {
      color var(--green)
    }
  }
}
</style>
