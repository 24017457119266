<template>
  <div class="order-m">
    <template v-if="order">
      <div class="order-m__top">
        <span class="order-m__top-title"> Заказ №{{ order.id }} </span>
        <div class="order-m__top-btns">
          <template v-if="order.shop">
            <span class="order-m__help">Нужна помощь?</span>
            <a
              v-if="order.shop.email"
              :href="`mailto:${order.shop.email}`"
              class="btn btn--gray order-m__send-btn"
            >
              <RemixIconComponent category="communication" name="message-3-line" />
              <span>Написать</span>
            </a>
            <a
              v-if="order.shop.phone"
              :href="`tel:${order.shop.phone}`"
              class="btn btn--outline order-m__phone-btn"
            >
              <RemixIconComponent category="device" name="phone-fill" />
              <span>Позвонить</span>
            </a>
          </template>
          <button class="btn btn--gray order-m__close-btn" @click="closeModal">
            <RemixIconComponent category="system" name="close-fill" />
          </button>
        </div>
      </div>
      <template v-if="isOffline">
        <div class="order-m__header">
          <span class="order-m__date">Дата покупки: {{ order.created_at | formatDateTime }}</span>
        </div>
      </template>
      <template v-else>
        <div class="order-m__header">
          <span class="order-m__date">Время доставки: {{ orderDateTime }}</span>
          <!--      <button class="btn btn&#45;&#45;gray btn&#45;&#45;sm">-->
          <!--        <RemixIconComponent category="design" name="pencil-line" />-->
          <!--        <span>Изменить</span>-->
          <!--      </button>-->
        </div>
      </template>
      <template v-if="!isOffline">
        <ul class="order-m__panel">
          <li
            v-for="panel in panelTabs"
            :key="panel.id"
            :class="{ 'order-m__panel--active': panel.id === order.status_id }"
          >
            <div class="order-m__panel-icon">
              <RemixIconComponent :category="panel.category" :name="panel.icon" />
            </div>
            <span class="order-m__panel-title">
              {{ panel.name }}
            </span>
          </li>
        </ul>
      </template>
      <template v-if="isFinished && !order.review">
        <div class="order-m__is-finished">
          <span class="order-m__is-finished-title">Заказ завершен</span>
          <button class="btn btn--md btn--white order-m__is-finished-btn" @click="openReviewModal">
            Оценить заказ
          </button>
        </div>
      </template>
      <div class="order-m__content">
        <section class="order-m__s">
          <div class="order-m__order-list-title">Состав заказа</div>
          <template v-if="!isOffline">
            <div class="order-m__content-header">
              <FilterTabs :tabs="tabs" :active-tab="product_status" @change-tab="changeTab" />
              <!--          <button class="btn btn&#45;&#45;gray">-->
              <!--            <RemixIconComponent category="system" name="add-line" />-->
              <!--            <span> Добавить товар </span>-->
              <!--          </button>-->
            </div>
          </template>
          <div class="order-m__content-body">
            <OrderProductCardComponent v-for="(item, i) in products" :key="i" :data="item" />
            <span v-show="!(products && products.length)">Товаров нет</span>
          </div>
        </section>
        <aside class="order-m__a">
          <div class="order-m__a-title">Детали заказа</div>
          <template v-if="order.client_info?.address && !isOffline">
            <div class="order-m__a-address-header">
              <span>Адрес доставки:</span>
              <span class="order-m__a-address-description">
                {{ order.client_info.address }}
              </span>
              <!--          <button class="btn btn&#45;&#45;gray order-m__a-btn-update">-->
              <!--            <RemixIconComponent category="design" name="pencil-line" />-->
              <!--          </button>-->
            </div>
          </template>
          <template v-if="order.shop && isOffline">
            <div class="order-m__a-shop-info">
              <div v-if="order.shop.address" class="order-m__a-address-header">
                <span class="order-m__a-address-title">Адрес магазина:</span>
                <span>
                  {{ order.shop.address }}
                </span>
              </div>
              <div v-if="order.shop.phone" class="order-m__a-shop-phone-header">
                <span class="order-m__a-address-title">Номер телефона:</span>
                <span>
                  {{ order.shop.phone | vMask("+# ### ###-##-##") }}
                </span>
              </div>
            </div>
          </template>
          <!--        <div class="order-m__a-btn">-->
          <!--          <button class="btn btn&#45;&#45;gray">Добавить комментарий</button>-->
          <!--        </div>-->
          <div v-if="order.client?.phone" class="order-m__a-phone">
            <span class="order-m__a-phone-title">Телефон:</span>
            <span>{{ order.client?.phone | vMask("+# ### ###-##-##") }}</span>
          </div>
          <div v-if="order.express" class="order-m__a-express">
            <span style="line-height: 26px;">Экспресс-доставка ⚡</span>
          </div>
          <div class="order-m__a-total">
            <div class="order-m__a-total-header">
              <div class="order-m__a-total-block">
                <span>Итого</span>
                <!--              <button class="btn btn&#45;&#45;outline order-m__a-total-header-btn">-->
                <!--                <RemixIconComponent category="system" name="arrow-right-s-line" />-->
                <!--              </button>-->
              </div>
              <div class="order-m__a-total-price">{{ order.amount }} ₽</div>
            </div>
            <!--          <div class="order-m__a-total-footer">-->
            <!--            <span>Промкод</span>-->
            <!--            <span>ХХХХХХХХ</span>-->
            <!--          </div>-->
          </div>
          <div class="order-m__a-pay">
            <span class="order-m__a-pay-title">
              <span>Способ оплаты:</span>
              <span>{{ order.payment_type_id === 1 ? "Картой онлайн" : "Наличная оплата" }}</span>
            </span>
            <button
              v-if="order.payment_type_id === 1"
              @click="orderRepeat"
              class="btn btn--green order-m__a-pay-repeat"
            >
              Повторить заказ
            </button>
            <button
              v-if="!isFinished && !isOffline"
              :disabled="isCanceled"
              @click="cancelOrderConfirm"
              class="btn btn--red order-m__a-pay-close"
            >
              <span>{{ isCanceled ? "Отменен" : "Отменить" }}</span>
              <IconComponent name="loading" v-if="loading" />
            </button>
          </div>
        </aside>
      </div>
    </template>
    <template v-else>
      <div class="order-m__loading">
        <IconComponent name="loading" />
      </div>
    </template>
  </div>
</template>

<script>
import FilterTabs from "../components/FilterTabs.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import MY_ORDER_ITEM from "@/graphql/queries/purchase/my_order.graphql";
import OrderProductCardComponent from "@/views/order/components/OrderProductCardComponent.vue";
import dayjs from "dayjs";
import CANCEL_ORDER from "@/graphql/mutations/purchase/OrderCancel.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";
import IconComponent from "components/IconComponent.vue";
import ReviewModal from "./ReviewModal.vue";
require("dayjs/locale/ru");
dayjs.locale("ru");

export default {
  name: "OrderModal",
  props: {
    id: Number,
    orderRepeat: Function,
  },

  data() {
    return {
      loading: false,
      panelTabs: [
        {
          id: 1,
          icon: "flashlight-fill",
          category: "weather",
          name: "Принят",
        },
        {
          id: 2,
          icon: "loader-2-line",
          category: "system",
          name: "Собираем",
        },
        {
          id: 3,
          icon: "archive-line",
          category: "business",
          name: "Упаковываем",
        },
        {
          id: 4,
          icon: "truck-line",
          category: "map",
          name: "Везём",
        },
        {
          id: 5,
          icon: "checkbox-circle-line",
          category: "system",
          name: "Доставили",
        },
      ],
      tabs: [
        {
          id: null,
          name: "Все",
        },
        {
          id: this.$store.state._statuses.PRODUCT_WAIT,
          name: "Ожидает",
        },
        {
          id: this.$store.state._statuses.PRODUCT_ASSEMBLED,
          name: "Собрано",
        },
        {
          id: this.$store.state._statuses.PRODUCT_CANCELED,
          name: "Отмены",
        },
      ],
      product_status: null,
      order: null,
    };
  },
  async mounted() {
    await this.get();
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
    products() {
      if (this.product_status) {
        return this.order?.items.filter((item) => item.status_id === this.product_status);
      }
      return this.order.items;
    },
    orderDateTime() {
      let day = dayjs(this.order.slot_start_date).format("D MMM");
      let slotStartTime = dayjs(this.order.slot_start_date).format("HH:mm");
      let slotEndTime = dayjs(this.order.slot_end_date).format("HH:mm");
      return `${day} → ${slotStartTime} - ${slotEndTime}`;
    },
    statuses() {
      return this.$store.state._statuses;
    },
    isFinished() {
      return this.order.status_id === this.statuses.ORDER_FINISHED;
    },
    isCanceled() {
      return this.order.status_id === this.statuses.ORDER_CANCELED;
    },
    isOffline() {
      return this.order.payment_type_id === this.$store.state._types.PAYMENT_OFFLINE;
    },
  },
  methods: {
    openReviewModal() {
      this.$store.state._modals.push({
        component: ReviewModal,
        options: {
          id: this.order?.id,
          callback: this.get,
        },
      });
    },
    cancelOrderConfirm() {
      this.$store.state._modals.push({
        component: AlertModal,
        options: {
          title: "Отмена заказа",
          message: "Вы действительно хотите отменить заказ?",
          isConfirm: true,
          closeBtnTitle: "Выйти",
          closeBtnClass: "btn--gray",
          callback: this.cancelOrder,
        },
      });
    },
    cancelOrder() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.clients.purchase
          .mutate({
            mutation: CANCEL_ORDER,
            variables: {
              order_id: this.id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            if (data.OrderCancel) {
              this.$store.state._modals.push({
                component: AlertModal,
                options: {
                  message: "Заказ отменен",
                  title: "Готово",
                },
              });
              this.$store.dispatch("GET_ORDERS", {
                apollo: this.$apollo.provider.clients,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    changeTab(tab) {
      if (tab.id === this.product_status) {
        return;
      }
      this.product_status = tab.id;
      console.log(this.product_status);
    },
    closeModal() {
      this.$emit("close");
    },
    async get() {
      await this.$apolloProvider.clients.purchase
        .query({
          query: MY_ORDER_ITEM,
          context: {
            fetchPolicy: "no-cache",
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
          variables: {
            id: this.id,
          },
        })
        .then(({ data }) => {
          this.order = data.my_orders_item;
        });
    },
  },
  components: {
    IconComponent,
    OrderProductCardComponent,
    RemixIconComponent,
    FilterTabs,
  },
};
</script>

<style lang="stylus">
.order-m {
  background var(--white)
  z-index 2
  border-radius 16px
  padding 16px
  width 100%
  max-width 1290px
  min-height 150px

  &__top {
    display flex
    justify-content space-between
    align-items center
    margin-bottom 18px
  }

  &__top-title {
    color var(--gray-900)
    line-height 29px
    font-weight 500
    font-size: 1.5em

    +below(680px) {
      font-size: 0.825rem
      line-height 18px
    }
  }

  &__top-btns {
    display flex
    align-items center
  }

  &__send-btn, &__phone-btn {
    font-size 0.875rem
    height 46px
    border-radius 16px
    padding 0 10px

    +below(680px) {
      & span {
        display none
      }
    }
  }

  &__phone-btn {
    margin-left 8px
  }

  &__send-btn {
    margin-left 16px
  }

  &__close-btn {
    width 46px
    height 46px
    margin-left 32px
  }

  &__header {
    display flex
    align-items center
    gap 16px
    padding 16px
    border 1px solid var(--border-color-2)
    border-radius 16px
    margin-bottom 16px

    button {
      width 120px
      border-radius 12px
    }
  }

  &__date {
    color var(--gray-900)
    font-weight: 400;
    font-size: 0.875em
  }

  &__panel {
    display grid
    grid-template-columns repeat(5, minmax(100px, 250px))
    border-radius 16px
    padding 4px
    background var(--gray-100)
    margin-bottom 32px

    +below(628px) {
      grid-template-columns repeat(2, 1fr)
    }

    li {
      display flex
      flex-direction column
      align-items center
      border-radius 16px
      gap 8px
      padding 8px
      transition var(--transition)
    }
  }

  &__panel--active {
    background var(--white)

    & .order-m__panel-icon {
      background var(--green-50)

      .icon {
        svg path {
          fill var(--green)
        }
      }
    }
  }

  &__help {
    +below(680px) {
      font-size: 0.725rem
      line-height 18px
    }
  }

  &__panel-icon {
    display flex
    justify-content center
    align-items center
    width 28px
    height 28px
    transition var(--transition)
    border-radius 999px

    .icon {
      width 20px
      height 20px

      svg path {
        transition var(--transition)
        fill var(--green-300)
      }
    }
  }

  &__panel-title {
    font-weight 500
    font-size 0.875em
    line-height 19px
  }

  &__order-list-title {
    display flex
    font-weight 500
    font-size 1.250em
    margin-bottom 16px
  }

  &__is-finished {
    display flex
    flex-direction column
    gap 8px
    align-items center
    justify-content center
    width 100%
    padding 16px
    background var(--green-50)
    border-radius 16px
    margin-bottom 36px
  }

  &__is-finished-title {
    color: var(--green-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }

  &__is-finished-btn {
    font-size: 0.750rem;
    font-style: normal;
    font-weight: 400;
    height 36px
    line-height: 12px;
  }

  &__content {
    width 100%
    display flex
    align-items flex-start
    gap 24px

    +below(768px) {
      flex-direction column
    }
  }

  &__s {
    width 100%
  }

  &__content-header {
    display flex
    justify-content space-between
    margin-bottom 16px

    button {
      border-radius 16px
      font-size 0.875em
    }
  }

  &__content-body {
    display flex
    flex-direction column
    gap 8px
  }

  &__a {
    border 1px solid var(--border-color-2)
    border-radius 16px
    padding 16px
    max-width 296px
    width 100%

    +below(768px) {
      max-width none
    }
  }

  &__a-title {
    font-weight: 500;
    font-size: 1.250em;
    line-height 24px
  }

  &__a-shop-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid var(--border-color-2);
    padding-bottom: 16px
  }

  &__a-address-header {
    display flex
    justify-content space-between
    margin-top 16px
    flex-direction column

    span {
      line-height 26px
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__a-shop-phone-header {
    display flex
    flex-direction column

    span {
      line-height 26px
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__a-address-title {
    font-weight: 400;
    font-size: 0.875em;
    line-height 26px
    color var(--body_muted)
  }

  &__a-btn-update {
    width 26px
    height 26px
    border-radius 8px

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--gray-800)
      }
    }
  }

  &__a-btn {
    margin 16px 0 16px 0
    border-top 1px solid var(--border-color-2)
    border-bottom 1px solid var(--border-color-2)
    padding 16px 0 16px 0

    button {
      width 100%
      font-weight: 400;
      font-size: 0.875em;
      height 46px
      border-radius 16px
    }
  }

  &__a-phone {
    display flex
    flex-direction column
    padding-bottom 14px
    padding-top 16px

    span {
      font-weight: 500;
      font-size: 0.875em;
    }
  }

  &__a-phone-title {
    font-weight: 400;
    font-size: 0.875em;
    color var(--body_muted)
  }

  &__a-express {
    font-weight: 400;
    font-size: 0.875em;
    line-height 26px
    color black
  }

  &__a-total {
    margin 16px 0 16px 0
    display flex
    flex-direction column
    border-top 1px solid var(--border-color-2)
    padding-top 10px
    gap 8px
  }

  &__a-total-header {
    display flex
    justify-content space-between
    align-items center
  }

  &__a-total-block {
    display flex
    justify-content center
    align-items center
    gap 4px
  }

  &__a-total-header-btn {
    border-radius 8px
    width 26px
    height 26px

    .icon {
      width 16px
      height 16px
    }
  }

  &__a-total-footer {
    display flex
    justify-content space-between
    align-items center

    font-weight: 400;
    font-size: 0.875rem;
    color var(--body_muted)
  }

  &__a-pay {
    display flex
    flex-direction column
  }

  &__a-pay-title {
    display flex
    justify-content space-between
    font-weight: 400;
    font-size: 0.875em;
    color var(--body_muted)
  }

  &__a-pay-repeat {
    border 1px solid transparent
    margin 16px 0 8px 0
    color var(--white)
    background var(--green)
    font-size 0.875rem
    height 46px

    &:hover {
      background var(--white)
      border-color var(--green)
      color var(--green)
    }
  }

  &__a-pay-close {
    font-size 0.875rem
    height 46px
    border-radius 16px
  }

  &__loading {
    display flex
    height 100%

    & .icon {
      width 32px
      margin: 0 auto
    }
  }

}
</style>
