<template>
  <div class="order-date" :class="{ 'order-date--error': error }">
    <div class="order-date__content">
      <div class="order-date__header">
        <template v-if="client_delivery_info && client_delivery_info.type_id === 2">
          <span class="order-date__title">Когда доставить?</span>
          <span class="order-date__subtitle"> Курьер свяжется с вами перед выездом </span>
        </template>
        <span v-else class="order-date__title">Когда заберете?</span>
      </div>
      <div class="order-date__bottom">
        <div class="order-date__buttons">
          <ProductParamSlider
            :slider-options="orderDateSliderOptions"
            :items="days"
            class="order-date__slider"
            v-slot="{ item }"
          >
            <button
              @click="setActiveDay(item)"
              :class="{ 'order-date__btn--white--active no-hover': activeDay === item }"
              class="btn btn--sm order-date__btn--white"
            >
              <span>{{ item | weekDate }}</span>
              <IconComponent name="icon-right-line" />
            </button>
          </ProductParamSlider>
        </div>
        <template v-if="activeDay && available_slots.length">
          <div class="order-date__time">
            <button
              v-for="(item, i) in available_slots"
              :key="i"
              @click="dateActive(item)"
              :class="{
                'order-date__btn--time--active no-hover': activeSlot === item,
                'order-date__btn--time-express': isExpressSlot(i),
              }"
              class="btn btn--sm order-date__btn--time"
            >
              <span class="order-date__btn-express" v-if="isExpressSlot(i)">
                Экспресс доставка
                <IconComponent name="lightning" />
              </span>
              <span v-else>{{ getTime(item.slot_start_date) }} - {{ getTime(item.slot_end_date) }}</span>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="order-date__empty">
            <span>На сегодня все интервалы закончились</span>
            <button @click="setNextDay" class="btn btn--sm btn--gray">Посмотреть интервалы на завтра</button>
          </div>
        </template>
      </div>
      <span class="order-date__error" v-if="error">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import AVAILABLE_SLOTS from "@/graphql/queries/purchase/available_slots.graphql";
import ProductParamSlider from "components/product/ProductParamSlider.vue";
import dayjs from "dayjs";

export default {
  name: "OrderDateComponent",
  components: { ProductParamSlider, IconComponent },
  props: {
    error: String,
  },
  data() {
    return {
      activeDay: undefined,
      activeSlot: undefined,
      available_slots: [],
      orderDateSliderOptions: {
        direction: "vertical",
        slidesPerView: 5,
        spaceBetween: 4,
        pagination: false,
        clickable: false,
      },
    };
  },
  mounted() {
    this.setActiveDay(this.days[0]);
  },
  computed: {
    client_delivery_info() {
      return this.$store.state.auth.client_delivery_info;
    },
    days() {
      const date = new Date();
      const dates = [];
      for (let i = 0; i < 30; i += 1) {
        dates.push(date.toLocaleDateString("en-CA"));
        date.setDate(date.getDate() + 1);
      }
      return dates;
    },
    token() {
      return this.$store.state.auth_token;
    },
    isExpressDelivery() {
      return (
        this.activeDay === this.days[0] && this.activeSlot === this.available_slots[0] && this.isFirstZone
      );
    },
    isFirstZone() {
      return this.client_delivery_info?.selected_address?.shop_zone?.type_id === 1;
    },
  },
  methods: {
    isExpressSlot(i) {
      return this.activeDay === this.days[0] && i === 0 && this.isFirstZone;
    },
    setNextDay() {
      // Исходная строка
      let dateString = this.activeDay;

      // Разбиваем строку на компоненты даты
      let parts = dateString.split("-");
      let year = parseInt(parts[0]);
      let month = parseInt(parts[1]) - 1; // Месяцы в объекте Date начинаются с 0
      let day = parseInt(parts[2]);

      // Создаем объект Date
      let date = new Date(year, month, day);
      date.setDate(date.getDate() + 1);

      this.activeDay = date.toLocaleDateString("en-CA");
      this.setActiveDay(this.activeDay);
    },
    setActiveDay(day) {
      this.activeDay = day;
      this.$apollo.provider.clients.purchase
        .query({
          query: AVAILABLE_SLOTS,
          variables: {
            date: day,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          this.available_slots = data.available_slots;
          // if (this.activeDay === this.days[0] && this.available_slots?.length) {
          //   this.dateActive(this.available_slots[0]);
          // }
        });
    },
    dateActive(item) {
      this.activeSlot = item;
      // Set express flag in store
      this.$store.state.order_page.express = this.isExpressDelivery;
      // Emit slot time as before
      this.$emit("chooseSlot", item.slot_start_date);
      // Emit express flag separately
      this.$emit("setExpress", this.isExpressDelivery);
    },
    getTime(date) {
      return dayjs(date).format("HH:mm");
    },
  },
};
</script>

<style lang="stylus">
.order-date {
  padding 16px
  border 1px solid var(--border-color-2)
  border-radius 16px
  width 100%

  &--error {
    border-color var(--red)
  }

  &__error {
    color var(--red)
  }

  &__content {
    display flex
    flex-direction column
    gap 16px
  }

  &__slider {
    height: 228px;
    gap: 4px;
    padding 0 !important
    flex-direction column

    .param-slider__arrow {
      border-radius: 8px;
      border: 1px solid var(--gray-100);
      background: var(--gray-100);
      position: static;
      margin 0
      width: 100%;
      height: 18px;
    }

  }
  &__header {
    display flex
    flex-direction column
  }

  &__empty {
    width 100%
    display flex
    flex-direction column
    justify-content center
    align-items center
    gap 8px

    & span {
      color var(--red)
      font-size: 0.875rem
    }
  }

  &__bottom {
    display flex
    gap 24px

    +below(660px) {
      flex-direction column
    }
  }

  &__title {
    font-size 1.25em
    font-weight 500
    line-height 120%
    color var(--gray-900)
  }

  &__subtitle {
    font-size .875em
    line-height 140%
    color var(--body_muted)
  }

  &__buttons {
    width 100%
    max-width 301px
    display flex
    flex-direction column
    gap 4px

    .param-slider__arrow {
      height 26px
    }
  }

  &__time {
    display grid
    grid-template-columns repeat(5, 1fr)
    height fit-content
    gap 4px
    width 100%
    +below(1400px) {
      grid-template-columns repeat(4, 1fr)
    }
    +below(1230px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(1100px) {
      grid-template-columns repeat(2, 1fr)
    }
  }

  &__btn {
    height 26px

    &--white {
      height 32px
      justify-content space-between
      border 1px solid var(--border-color-2)
      width 100%

      &:hover {
        border 1px solid
      }

      &--active {
        height 32px
        background var(--gray-900)
        color var(--white)

        .icon svg path {
          fill var(--white)
        }
      }
    }

    &--time {
      height 32px
      border 1px solid var(--border-color-2)
      justify-content center

      &:hover {
        border 1px solid
      }

      &--active {
        height 32px
        background var(--gray-900)
        color var(--white)

        .icon svg path {
          fill var(--white)
        }
      }

      &-express {
				grid-column span 2
			}

			&--active&-express {
				background var(--main)
				color: var(--white)
			}
    }

		&-express {
			display flex
			align-items center
			gap: 5px

			.icon svg path {
				fill var(--yellow)
				stroke var(--yellow)
			}
		}
	}
}
</style>
